import * as types from "../types";

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.ADD_SERVICE:
      if (state.items.find((item) => item.name == payload.name)) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          items: [...state.items, payload],
        };
      }

    case types.REMOVE_SERVICE: {
      return {
        ...state,
        items: state.items.filter((item) => item.name !== payload),
      };
    }

    case types.CLEAR_SERVICES: {
      return {
        items: [],
      };
    }

    default:
      return state;
  }
}
