import React from "react";
import Router from "./Router";
import axios from "axios";

//Set base url for all api requests
axios.defaults.baseURL = process.env.REACT_APP_API;

function App() {
  return <Router />;
}

export default App;
