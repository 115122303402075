import * as types from "../types";

const initialState = {
  firstName: "",
  surName: "",
  email: "",
  number: "",
  address: "",
  city: "",
  postal: "",
  venue: "",
  location: "",
  type: "",
  when: "",
  fetching: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.BOOKING_FETCHING:
      return {
        ...state,
        fetching: true,
      };

    case types.SET_BOOKING_DETAILS:
      return {
        ...state,
        ...payload,
        fetching: false,
      };

    default:
      return state;
  }
}
