import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "./components/Loader";

const Homepage = lazy(() => import("./pages/Home"));
const BandsPage = lazy(() => import("./pages/Bands"));
const AvailabilityChecker = lazy(() => import("./pages/AvailabilityChecker"));
const BandAvailabilityChecker = lazy(() => import("./pages/BandAvailability"));
const AvailableBands = lazy(() => import("./pages/AvailableBands"));
const Weddingbands = lazy(() => import("./pages/WeddingBands"));
const DJ = lazy(() => import("./pages/DJ"));
const DaytimeActs = lazy(() => import("./pages/DaytimeBands"));
const Extras = lazy(() => import("./pages/Extras"));
const Bandpage = lazy(() => import("./pages/Band"));
const Cart = lazy(() => import("./pages/Cart"));
const BookingDetails = lazy(() => import("./pages/BookingDetails"));
const Checkout = lazy(() => import("./pages/Checkout"));
const Confirm = lazy(() => import("./pages/Confirm"));
const CovidGuarantee = lazy(() => import("./pages/CovidGuarantee"));
const PrivacyPolicyAndTermsAndConditions = lazy(() => import("./pages/PrivacyPolicyAndTermsAndConditions"));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

function Router() {
  return (
    <Suspense fallback={Loader}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/bands" component={BandsPage} />
          <Route exact path="/bands/available" component={AvailableBands} />
          <Route exact path="/availability" component={AvailabilityChecker} />
          <Route
            exact
            path="/availability/:name"
            component={BandAvailabilityChecker}
          />
          <Route exact path="/wedding-bands" component={Weddingbands} />
          <Route exact path="/dj-bands" component={DJ} />
          <Route exact path="/daytime-acts" component={DaytimeActs} />
          <Route exact path="/wedding-extras" component={Extras} />
          <Route exact path="/band/:name" component={Bandpage} />
          <Route exact path="/bands/:name/cart" component={Cart} />
          <Route
            exact
            path="/bands/:name/booking-details"
            component={BookingDetails}
          />
          <Route exact path="/bands/:name/confirmed" component={Confirm} />
          <Route
            exact
            path="/bands/:name/checkout"
            render={(props) => (
              <Elements stripe={stripePromise}>
                <Checkout {...props} />
              </Elements>
            )}
          />
          <Route exact path="/covid-guarantee" component={CovidGuarantee} />
          <Route exact path="/privacy-policy-and-terms-and-conditions" component={PrivacyPolicyAndTermsAndConditions} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;
