import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

function Loader() {
  return (
    <div className="full-page">
      <div className="container">
        <LoadingOutlined />
      </div>
    </div>
  );
}

export default Loader;
